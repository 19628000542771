import React, { ReactElement, useEffect } from 'react';
import { graphql } from 'gatsby';
import SEO from '@Components/SEO';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import '@GraphQL/fragments';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import ContentfulModule from '@Src/components/ContentfulModule';
import ContentLayoutIndex from '@Src/components/sitestructure/ContentLayoutIndex';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeLanding: IPageData;
	};
}

const IndexPage = ({ data, location, transitionStatus }: Props): ReactElement => {
	const { title, seoSettings, content } = data.contentfulPageTypeLanding;
	const { theme, setTheme: setPageTheme } = usePageTheme();
	const { setPageType, setPageTitle } = useApp();

	const { mode, setMode } = useApp();

	useEffect(() => {
		setPageType('index');
	}, []);

	useEffect(() => {
		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
			setPageTitle('index');
			//setPageType('index');
		}
	}, [transitionStatus]);

	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
		}
	}, [mode]);

	return (
		<ContentLayoutIndex transitionState={transitionStatus}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			{content &&
				content.map(
					(module: IBlock, index: number): ReactElement => (
						<ContentfulModule key={module.id} type={module.type} data={module} />
					)
				)}
		</ContentLayoutIndex>
	);
};

export default IndexPage;

export const query = graphql`
	query {
		contentfulPageTypeLanding {
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;