import React, { createRef, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import { useWindowResize } from '@Src/utils/useWindowResize';
import * as styles from './index.module.scss';
export interface Props {
	/**
	 * Children to render
	 */
	children: ReactNode;
	/**
	 * Classname to be merged
	 */
	className?: string;

	transitionState?: string;
}

const ContentLayoutIndex = ({ children, transitionState }: Props): ReactElement => {
	const [state, setState] = useState<string | undefined>(transitionState);
	const { theme } = usePageTheme();
	const windowSize = useWindowResize();
	const [fontSize, setFontSize] = useState(0);
	const mountRef = createRef<HTMLDivElement>();
	const { loaded, mode, cookieConsentOpen } = useApp();
	const [visible, setVisible] = useState(false);
	const initialLoad = useRef(loaded);
	const [localTheme, setLocalTheme] = useState('');

	useEffect(() => {
		if (visible) resizeFont(true);
	}, [windowSize]);

	useEffect(() => {
		setLocalTheme(mode == 'day' ? 'yellow' : 'dark');
	}, [mode]);

	const resizeFont = (performResize: boolean) => {
		if (!mountRef.current || !mountRef.current.firstChild || !mountRef.current.clientHeight) return false;
		const child = mountRef.current.firstChild as HTMLDivElement;
		const h = child.clientHeight;
		const hMax = mountRef.current.clientHeight;
		const fs = Math.round(parseFloat(window.getComputedStyle(child, null).getPropertyValue('font-size')));
		const m = windowSize.width <= 992.5 ? 0.04 : 0.015;
		const targetFS = Math.round(m * windowSize.width);
		if (h > hMax) {
			performResize && setFontSize(Math.round(fs) - 2);
			return true;
		} else {
			performResize && setFontSize(targetFS);
			return false;
		}
	};

	useEffect(() => {
		if (!mountRef.current || !mountRef.current.firstChild || !visible) return;
		const child = mountRef.current.firstChild as HTMLDivElement;
		child.style.fontSize = fontSize + 'px';
		const needsResize = resizeFont(false);
		if (needsResize) resizeFont(true);
	}, [fontSize]);

	useEffect(() => {
		setState(transitionState);
	}, [transitionState]);

	useEffect(() => {
		resizeFont(true);
	}, [visible]);

	useEffect(() => {
		if (loaded)
			setTimeout(
				() => {
					setVisible(true);
				},
				initialLoad.current ? 0 : 0
			);
	}, [loaded]);

	return (
		<div
			ref={mountRef}
			className={cx(
				styles.container,
				localTheme && styles[localTheme],
				state && styles[state],
				visible && styles.visible,
				!initialLoad.current && styles.introAnim,
				cookieConsentOpen && styles.cookieConsentOpen
			)}
		>
			{children}
		</div>
	);
};

export default ContentLayoutIndex;
